// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compileargonimport("jquery/dist/jquery.min.js")
import("jquery/dist/jquery.min.js")
import("bootstrap/dist/js/bootstrap.min.js")
import("controllers/jquery.mask.js")
import("datepicker/src/calendarmodel")
import("controllers/login_page.js")
import("controllers/faq_page.js")
import("controllers/updated_value_page.js")
import("controllers/market_value_form.js")
import("controllers/court_fields.js")
import("controllers/payment_date_page.js")
import("controllers/creditor.js")
import("controllers/custom.js")
import("controllers/docs_upload.js")
import("moment/moment.js")
import("wowjs/dist/wow.min.js")
import("controllers/wow.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
